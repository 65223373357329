<template>
     <CForm ref="form" class="needs-validation">
        <CRow>
            <CCol col="6">
                <CInput
                    label="Başlık"
                    placeholder="İlan Başlığı"
                    type="text"
                    v-model="data.title"
                    horizontal
                    required
                />
            </CCol>
            <CCol col="4">
                <CInput
                    label="Sıra"
                    placeholder="Görüntülenme Sırası"
                    type="number"
                    v-model="data.displayOrder"
                    horizontal
                    required
                />
            </CCol>
            <CCol style="display: flex; align-items: center; margin-bottom: .5rem;">
                <span style="margin-right: .5rem;">Aktif </span>
                <CSwitch
                    class="mr-1"
                    size="lg"
                    color="primary"
                    :checked.sync="data.activeFlag"
                />
            </CCol>
        </CRow>
        <CRow>
            <CCol col="6">
                <CSelect
                    label="Pozisyon"
                    type="text"
                    horizontal
                    placeholder="Seçiniz"
                    :options="careerPositions"
                    :value.sync="data.careerPositionId"
                    required
                />
            </CCol>
            <CCol col="6">
                <CSelect
                    label="Dil"
                    type="text"
                    placeholder="Seçiniz"
                    :options="langs"
                    :value.sync="data.langId"
                    horizontal
                    required
                />
            </CCol>
        </CRow>
        <CRow>
            <VueEditor v-model="data.description" />
        </CRow>
    </CForm>
</template>

<script>
import { VueEditor } from "vue2-editor";

    export default{
        name: "contentForm",
        props:{
            validated: Boolean,
            params: Object,
            actionType: String,
        },
        components:{
         VueEditor
        },
        computed:{
            langs: function() {
                let data = ['Dil Seçin']
                this.$store.getters.langs.map(r => data.push({value:r.id, label: r.name}))
                return data
            },
            careerPositions: function() {
                let data = ['Pozisyon Seçin']
                this.$store.getters.careerPositions.map(r => data.push({value:r.id, label: r.name}))
                return data
            }
        },
        watch:{
            params: function(val){
                this.data = {...val};
            },
            actionType:  function(val){
                if(val == 'create')
                    this.data = {...this.params}
            },
        },
        data: function(){
            return{
                data: this.params ? {...this.params} : {},
            }
        },
    }
</script>